import { useState, useEffect, useRef } from 'react';

const useIdle = (idleTimeout = 120000) => {
  const [isIdle, setIsIdle] = useState(false);
  const lastActivityRef = useRef(performance.now());
  const timeoutIdRef = useRef(null);

  const resetIdleTimer = () => {
    clearTimeout(timeoutIdRef.current);
    setIsIdle(false);
    lastActivityRef.current = performance.now();
    timeoutIdRef.current = setTimeout(() => {
      setIsIdle(true);
    }, idleTimeout);
  };

  const handleActivity = () => {
    resetIdleTimer();
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const timeElapsed = performance.now() - lastActivityRef.current;

        if (timeElapsed > idleTimeout) {
          setIsIdle(true);
        } else {
          resetIdleTimer();
        }
      }
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('click', handleActivity);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    resetIdleTimer();

    return () => {
      clearTimeout(timeoutIdRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [idleTimeout]);

  return isIdle;
};

export default useIdle;
