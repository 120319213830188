import React, { useState, useEffect } from 'react';
import { useNetworkStatus } from '../context/network-status.context';
import useIdle from '../hooks/useIdle.hook';
import ChatPortal from './ChatPortal';

const ChatPortalWrapper = () => {
  const { isOnline } = useNetworkStatus();
  const isIdle = useIdle();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!showPopup) {
      // Show popup if user is either offline or idle
      if (!isOnline || isIdle) {
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    }
  }, [isOnline, isIdle]);

  const handleReload = () => {
    // Trigger page reload when the user clicks the "Retry" button
    window.location.reload();
  };

  return (
    <div className="relative">
      {showPopup && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-50 flex items-center justify-center transition-all duration-300 ease-in-out">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm mx-auto transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
            {/* Icon */}
            <div className="flex justify-center mb-4">
              {!isOnline ? (
                <svg
                  className="w-16 h-16 text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a8 8 0 11-8 8 8 8 0 018-8zm0 2a6 6 0 100 12 6 6 0 000-12zm-1 3a1 1 0 112 0v2a1 1 0 01-2 0V7zm0 4a1 1 0 112 0v2a1 1 0 01-2 0v-2z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  className="w-16 h-16 text-yellow-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a8 8 0 11-8 8 8 8 0 018-8zm0 2a6 6 0 100 12 6 6 0 000-12zm-1 3a1 1 0 112 0v2a1 1 0 01-2 0V7zm0 4a1 1 0 112 0v2a1 1 0 01-2 0v-2z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>

            {/* Title */}
            <h2 className="text-2xl font-semibold text-center mb-4 text-gray-800">
              {!isOnline
                ? 'Network issue: Please check your connection'
                : 'Idle'}
            </h2>

            {/* Description */}
            <p className="text-center text-gray-600 mb-6">
              {!isOnline
                ? 'It seems like your internet connection is down. Please reconnect and try again.'
                : 'You have been Idle for a while. click on "Re-connect" to continue interacting with patients.'}
            </p>

            {/* Retry Button */}
            {isOnline && (
              <button
                onClick={handleReload}
                className="w-full px-6 py-3 bg-primary text-white rounded-lg shadow-md transition-colors duration-300"
              >
                Re-connect
              </button>
            )}
          </div>
        </div>
      )}

      {/* Render ChatPortal if online and not idle */}
      {/* {isOnline && !isIdle && <ChatPortal />} */}
      <ChatPortal />
    </div>
  );
};

export default ChatPortalWrapper;
