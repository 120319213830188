import React, { createContext, useContext, useEffect, useState } from 'react';

// Create the context
const NetworkStatusContext = createContext({
  isOnline: true,
  isReconnecting: false,
});

// Provider Component
export const NetworkStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setIsReconnecting(false);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setIsReconnecting(true);

      // Simulate a brief reconnecting state
      setTimeout(() => {
        if (navigator.onLine) {
          setIsReconnecting(false);
        }
      }, 2000); // Adjust time as needed
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider value={{ isOnline, isReconnecting }}>
      {children}
    </NetworkStatusContext.Provider>
  );
};

// Custom hook for accessing network status
export const useNetworkStatus = () => {
  return useContext(NetworkStatusContext);
};
