import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ChatPortalWrapper from './ChatPortalWrapper';
// import ChatPage from './ChatPage';
// import PatientsListPage from './PatientsListPage';

import '../styles/main.css';

const AppRoutes = () => {
  const fetchNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        alert('Please allow notifications to receive updates.');
        return;
      }
      console.log('Notification permission granted.');
    } catch (error) {
      console.log('[WEB NOTIFICATION ERROR]: ', error);
    }
  };

  useEffect(() => {
    fetchNotificationPermission();
  }, []);

  return (
    <Router>
      <Routes>
        {/* <Route path="/patients-list" Component={PatientsListPage} /> */}
        {/* <Route path="/chat" Component={ChatPage} /> */}
        <Route path="/chat" Component={ChatPortalWrapper} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
